.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


#loader-content{
  position: relative;
  width: 110px;
  margin:auto;
  padding: 100px 0;
} 
#loader-1{
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 50%;
} 
 #loader-1 span, #loader-content span{
   display: inline-block;
   height: 15px;
   width: 15px;
   background: #01ACFA;
   border-radius: 0px;
}
 #loader-1 span:nth-child(1), #loader-content  span:nth-child(1){
   border-radius: 500px;
   animation: scale 1s 0.1s infinite cubic-bezier(0.600,-0.28,0.735,0.045);
}
 #loader-1 span:nth-child(2) , #loader-content  span:nth-child(2){
   border-radius: 500px;
   animation: scale 1s 0.2s infinite cubic-bezier(0.600,-0.28,0.735,0.045);
}
 #loader-1 span:nth-child(3) , #loader-content  span:nth-child(3){
   border-radius: 500px;
   animation: scale 1s 0.3s infinite cubic-bezier(0.600,-0.28,0.735,0.045);
}
 #loader-1 span:nth-child(4) , #loader-content  span:nth-child(4){
   border-radius: 500px;
   animation: scale 1s 0.4s infinite cubic-bezier(0.600,-0.28,0.735,0.045);
}
 #loader-1 span:nth-child(5) , #loader-content  span:nth-child(5){
   border-radius: 500px;
   animation: scale 1s 0.5s infinite cubic-bezier(0.600,-0.28,0.735,0.045);
}
 @keyframes scale {
   0% {
     transform: scale(0.0);
  }
   25% {
     transform: scale(0.9,0.9);
     background: #50c8ff;
  }
   50% {
     transform: scale(1,1);
     margin: 0 3px;
     background: #01ACFA;
  }
   100% {
     transform: scale(0.0);
  }
}
